export default {
    data: () => ({
        sorting: {
            sort: 'desc',
            by: 'id'
        },
    }),
    methods: {
        sort(code) {
            let current = this.sorting.by
            if (current !== '') {
                if (code === current) {
                    switch (this.sorting.sort) {
                        case '':
                            this.sorting.sort = 'desc';
                            break;
                        case 'desc':
                            this.sorting.sort = 'asc';
                            break;
                        case 'asc':
                            this.sorting.sort = 'desc';
                            this.sorting.by = 'id';
                            break;
                    }
                } else {
                    this.sorting.sort = 'desc'
                    this.sorting.by = code
                }
            } else {
                this.sorting.sort = 'desc'
                this.sorting.by = code
            }

        }
    }
}